<template>
  <div class="app-container">
    <div class="page-title">
      <h2>Le Conteneur</h2>
    </div>
    <el-card v-if="checkRole(['admin'])" class="box-card filter-card just-for-admin">
      <div slot="header" class="clearfix" />
      <div class="filter-container">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <el-tag type="dark">
              Numéro : <span style="font-weight:bold">{{ conteneur.num_cont }}</span>
            </el-tag>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <el-tag type="info">Numéro de Plomb : 🎯 <span style="font-weight:bold">{{ conteneur.num_pb }}</span></el-tag>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <el-tag type="info">Poids Net (t) : <span style="font-weight:bold">{{ conteneur.pds_total }} t</span></el-tag>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <el-tag type="info">Nombre Sacs : 🚉 <span style="font-weight:bold">{{ conteneur.nbr_sac }}</span>
            </el-tag>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <el-card class="box-card">
      <div class="filter-container">
        <el-input v-model="board_sac_search_keyword" placeholder="Rechercher" class="wd-200 mr-15 filter-item" clearable @input="board_find_sac(board_sac_search_keyword)" />
        <el-link type="primary" class="wd-20 filter-item" @click="initSearch">Réinitialiser</el-link>
        <!-- <el-radio v-model="activeList" style="float: right;" label="rejetés" border @change="getSubSacList('rejetés')">Sacs rejetés</el-radio>
        <el-radio v-model="activeList" style="float: right;" label="disponibles" border @change="getConteneur">Tout les sacs</el-radio> -->
      </div>
      <h3 style="color:#30B08F">Les sacs {{ activeList != 'disponibles' ? '(' + activeList + ')' : '' }}</h3>
      <el-row :gutter="12">
        <el-col v-for="item in list" :key="item.code" :span="6">
          <el-card shadow="never">
            <el-row :gutter="12">
              <div class="text item">
                <el-tag type="info">
                  <el-avatar :size="60" src="https://res.cloudinary.com/dwj6sdjxn/image/upload/v1669370400/fertilizer_2_amfrld.png" />
                  <span style="font-weight:bold; font-size:medium" class="codeclass">
                    {{ item.code+ ' / ' + item.poids + ' Kg ' }}
                  </span>
                </el-tag>
                <el-tag size="small"><span style="font-weight:bold"><a @click="getProducteurLink(item.producteur.id)">{{ 'PRODUCTEUR : ' + item.producteur.nom + ' ' + item.producteur.prenom }}</a></span></el-tag>
                <el-tag size="small"><span style="font-weight:bold"><a @click="getRecolteLink(item.recolte.id)">{{ 'COLLECTE : ' + item.recolte.code }}</a></span></el-tag>
                <el-tag size="small"><span style="font-weight:bold"><a @click="getParcelleLink(item.recolte.parcelle.id)">{{ 'PARCELLE : ' + item.recolte.parcelle.code }}</a></span></el-tag>
                <el-popconfirm
                  confirm-button-text="Oui"
                  cancel-button-text="Non, Merci"
                  icon="el-icon-info"
                  icon-color="red"
                  title="Voulez-vous retirer ce sac de ce conteneur?"
                  @confirm="retirerSac(item.id)"
                  @cancel="cancelMessage()"
                >
                  <el-button slot="reference" type="info" size="small" style="margin-left:0px; margin-top:3px; float:right;"> Retirer</el-button>
                </el-popconfirm>
              </div>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
      <!-- pagination v-if="total > 0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getProduitsList" /-->

      <!-- ======= REGION USER DIALOG ======= -->

      <!-- ======= REGION USER DIALOG ======= -->

    </el-card>
  </div>
</template>

<script>
// import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
const conteneurResource = new Resource('conteneurs');
const sacsResource = new Resource('sacs');

export default {
  name: 'ProduitsList',
  // components: { Pagination },
  directives: { waves, permission, role },
  data() {
    return {
      tableData: [],
      tableHeader: [],
      drawer: false,
      conteneur: {},
      list: [],
      total: 0,
      detail: false,
      detailData: [],
      detailM: false,
      detailDataM: null,
      loading: true,
      downloading: false,
      produitCreating: false,
      editing: false,
      activeList: 'disponibles',
      communeFilter: null,
      villageFilter: null,
      producteurFilter: null,
      currentProduit: {},
      activeName: '1',
      affectSacConteneurDialog: false,
      conteneurList: [],
      conteneur_id: null,
      conteneur_beneficiaire_id: null,
      sacsSelection: [],
      sac_search_keyword: '',
      board_sac_search_keyword: '',
      query: {
        page: 1,
        limit: this.$limit,
        keyword: '',
      },
      dialogCreateProduit: false,
      currentProduitId: 0,
      rules: {
        nom: [{ required: true, message: this.$t('produit.NomRequired'), trigger: 'blur' }],
      },
      fileList: [],
      listSacs1: [],
      listSacs2: [],
      select_list: [],
    };
  },
  computed: {

  },
  created() {
    this.getConteneur();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array) {
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('produit.nom'), this.$t('general.description')];
        const filterVal = ['id', 'nom', 'description'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Liste des produits SCIAGRI_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    onChange(file, fileList) {
      var a = this.fileList;
      a.push({ name: file.name, url: file.url });
      // console.log('onChange begin');
      this.fileList = a;
      // console.log(this.fileList);
      // console.log('onChange end');
    },
    initSacSearchList() {
      this.listSacs2 = this.list;
      this.listSacs1 = [];
    },
    initSearch() {
      this.handleFilterLots();
    },
    handleFilterLots() {
      this.query.page = 1;
      this.list = [];
      this.getConteneur();
    },
    /* async getLotsList() {
      this.loading = true;
      const { data } = await lotsResource.get(this.$route.params.id);
      this.lot = data;
      this.loading = false;
    }, */
    async getConteneur() {
      this.loading = true;
      const { data } = await conteneurResource.get(this.$route.params.id);
      console.log('datagetConteneur', data);
      this.conteneur = data;
      this.list = data.sacs;
      this.loading = false;
    },
    getRecolteLink(id){
      console.log('recolteId : ', id);
      this.$router.push({ path: '/recoltes/' + id });
    },
    getProducteurLink(id){
      console.log('producteurId : ', id);
      this.$router.push({ path: '/producteurs/' + id });
    },
    getParcelleLink(id){
      console.log('parcelleId : ', id);
      this.$router.push({ path: '/parcelles/' + id });
    },
    async retirerSac(id){
      await sacsResource.update(id, { 'conteneur_id': null }).then(response => {
        this.$message({
          type: 'success',
          message: 'Sacs retiré avec succès',
        });
        this.getConteneur();
      }).catch(error => {
        this.$message({
          type: 'error',
          message: 'Echec',
        });
        console.log('error ', error);
      });
    },
    board_find_sac(name){
      const results = [];
      if (name === ''){
        this.getConteneur();
      } else {
        this.list.forEach((sac) => {
          if (sac.code.includes(name.toUpperCase()) || sac.producteur.nom.toUpperCase().includes(name.toUpperCase()) || sac.producteur.prenom.toUpperCase().includes(name.toUpperCase())){
            results.push(sac);
          }
        });
        this.list = results;
      }
    },
    cancelMessage(){
      this.$message({
        type: 'info',
        message: 'Annulé !',
      });
    },
    async getSubSacList(indication) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      const { data } = await conteneurResource.get(this.$route.params.id);
      if (indication === 'rejetés') {
        var listSacs2Filtre = [];
        data.sacs.forEach(element => {
          if (element.rejeter != null) {
            listSacs2Filtre.push(element);
          }
        });
        this.list = listSacs2Filtre;
      }
      loading.close();
    },
  },
};
</script>

<style lang="scss" scoped>
  span.el-tag.el-tag--info.el-tag--medium.el-tag--light {
    text-align: center;
    width: 100%;
    display: block;
    height: auto;
  }
  span.el-tag.el-tag--dark.el-tag--medium.el-tag--light {
    text-align: center;
    width: 100%;
    display: block;
    height: auto;
  }
  .codeclass {
    float:left;
  }
  .text {
    font-size: 24px;
  }

  .item {
    margin-bottom: 28px;
  }
.edit-input {
  padding-right: 100px;
}

.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}

.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}

.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;

  .block {
    float: left;
    min-width: 250px;
  }

  .clear-left {
    clear: left;
  }
}

</style>
