<template>
  <div class="app-container">
    <div class="page-title">
      <h2>Le Lot</h2>
    </div>
    <el-card v-if="checkRole(['admin'])" class="box-card filter-card just-for-admin">
      <div slot="header" class="clearfix">
        <!-- <span>{{ $t('general.filter') }}</span> -->
        <el-row :gutter="20">
          <el-col :span="13">
            <el-button v-if="lot" type="info" icon="el-icon-download" @click="telechargerBonDeLivraison()">Télécharger le bon de livraison</el-button>
            <el-popconfirm
              confirm-button-text="Oui"
              cancel-button-text="Non"
              icon="el-icon-info"
              icon-color="#626AEF"
              title="Cette opération va générer une nouvelle fiche de collecte. Voulez vous continuer cette action ?"
              @confirm="telechargerFicheCollecte"
              @cancel="cancelMessage"
            >
              <template #reference>
                <el-button v-if="lot" type="danger" size="mini" icon="el-icon-download" round>S.O.S Fiche de collecte</el-button>
              </template>
            </el-popconfirm>
            <el-popconfirm
              confirm-button-text="Oui"
              cancel-button-text="Non"
              icon="el-icon-info"
              icon-color="#626AEF"
              title="Voullez-vous marquer ce Lot comme étant livré?"
              @confirm="lotLivre"
              @cancel="cancelMessage"
            >
              <template #reference>
                <el-button v-if="!lot.date_livr" type="warning" icon="el-icon-check">Lot livré</el-button>
              </template>
            </el-popconfirm>
          </el-col>
          <el-button style="float: right;" type="secondary" @click="affectSacConteneurDialog=true">Mettre sacs dans un conteneur
          </el-button>
        </el-row>
      </div>
      <div class="filter-container">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <el-tag type="info">Code Lot : <span style="font-weight:bold">{{ lot.code }}</span>
            </el-tag>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <el-tag type="dark">
              Produit transporté : <span v-if="lot.produit" style="font-weight:bold">{{ lot.produit.nom }}</span>
            </el-tag>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <el-tag type="info">Nbr. sacs : 🎯 <span style="font-weight:bold">{{ lot.nbr_sac }}</span></el-tag>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <el-tag type="info">Poids total : <span style="font-weight:bold">{{ lot.pds_total }} t</span></el-tag>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <el-tag type="info">Sté de transport : 🚉 <span style="font-weight:bold">{{ lot.ste_trans }}</span>
            </el-tag>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <el-tag type="info">Camion : 🚂 <span style="font-weight:bold">{{ lot.im_camion }}</span></el-tag>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <el-tag type="info">
              Date chargement : 🕛 <span style="font-weight:bold">{{ lot.date_chrgmnt_libelle }}</span>
            </el-tag>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <el-tag v-if="lot.status === 'TERMINER'" type="success">
              Statut : 🚦 <span style="font-weight:bold">LIVRÉ</span>
            </el-tag>
            <el-tag v-if="lot.status === 'EN COURS'" type="danger">
              Statut : 🚦 <span style="font-weight:bold">EN COURS</span>
            </el-tag>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <el-tag v-if="lot.status === 'TERMINER'" type="info">
              Date livraison : 🕛 <span style="font-weight:bold">{{ lot.date_livr_libelle }}</span>
            </el-tag>
          </el-col>
        </el-row>
        <!-- <div style="display:flex; flex-direction:row">
          <el-select
            v-if="communeFilter !== null"
            v-model="villageFilter"
            placeholder="Village"
            style="margin-right:10px; width:200px"
            @change="filterByVillage(value)"
          >
            <el-option v-for="item in villages" :key="item.id" :label="item.nom" :value="item.id" />
          </el-select>
        </div> -->
      </div>
    </el-card>

    <el-card class="box-card">
      <div class="filter-container">
        <el-input v-model="board_sac_search_keyword" placeholder="Rechercher" class="wd-200 mr-15 filter-item" clearable @input="board_find_sac(board_sac_search_keyword)" />
        <el-link type="primary" class="wd-20 filter-item" @click="initSearch">Réinitialiser</el-link>
        <el-radio v-model="activeList" style="float: right;" label="rejetés" border @change="getSubSacList('rejetés')">Sacs rejetés</el-radio>
        <el-radio v-model="activeList" style="float: right;" label="disponibles" border @change="getLotInfos">Tout les sacs</el-radio>
      </div>
      <h3 style="color:#30B08F">Les sacs {{ activeList != 'disponibles' ? '(' + activeList + ')' : '' }}</h3>
      <el-row :gutter="12">
        <el-col v-for="item in list" :key="item.code" :span="6">
          <el-card shadow="never">
            <el-row :gutter="12">
              <div class="text item">
                <el-tag type="info">
                  <el-avatar :size="60" src="https://res.cloudinary.com/dwj6sdjxn/image/upload/v1669370400/fertilizer_2_amfrld.png" />
                  <span style="font-weight:bold; font-size:medium" class="codeclass">
                    {{ item.code+ ' / ' + item.poids + ' Kg ' }}
                  </span>
                </el-tag>
                <el-tag size="small"><span style="font-weight:bold"><a @click="getProducteurLink(item.producteur.id)">{{ 'PRODUCTEUR : ' + item.producteur.nom + ' ' + item.producteur.prenom }}</a></span></el-tag>
                <el-tag size="small"><span style="font-weight:bold"><a @click="getRecolteLink(item.recolte.id)">{{ 'COLLECTE : ' + item.recolte.code }}</a></span></el-tag>
                <el-tag size="small"><span style="font-weight:bold"><a @click="getParcelleLink(item.recolte.parcelle.id)">{{ 'PARCELLE : ' + item.recolte.parcelle.code }}</a></span></el-tag>
                <el-popover
                  v-if="! item.rejeter"
                  placement="bottom"
                  title="Options"
                  width="200"
                  trigger="click"
                  style="float: right;"
                >
                  <el-button style="margin-left:0px; margin-top:3px" @click="dechargerSac(item.id)"> Déchargé</el-button>
                  <el-button slot="reference" type="info" size="small" icon="el-icon-more" />
                  <el-button style="margin-left:0px; margin-top:3px" @click="rejeterSac(item.id)"> Rejeté </el-button>
                </el-popover>
              </div>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
      <!-- pagination v-if="total > 0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getProduitsList" /-->

      <!-- ======= REGION USER DIALOG ======= -->

      <!-- ======= REGION USER DIALOG ======= -->

    </el-card>

    <el-dialog title="Mettre des sacs dans un conteneur" :visible.sync="affectSacConteneurDialog">
      <div class="components-container">
        <el-input v-model="sac_search_keyword" placeholder="Rechercher" class="wd-200 mr-15 filter-item" clearable @input="findSac(sac_search_keyword)" />
        <el-select v-model="conteneur_beneficiaire_id" class="wd-200 mr-15 filter-item" placeholder="Conteneur Beneficiaire" style="width: 100%;" prop="conteneur_id">
          <el-option
            v-for="(conteneur, index) in conteneurList"
            :key="index"
            :label="conteneur.num_cont+'(num_pb: '+conteneur.num_pb+')'"
            :value="conteneur.id"
            :disabled="!conteneur.is_active"
          />
        </el-select>
        <el-button class="wd-50 mr-15 filter-item" type="primary" @click="envoyerSacConteneur(conteneur_beneficiaire_id)"> Ajouter au conteneur </el-button>
        <div class="editor-container">
          <el-button v-if="listSacs1.length == 0" type="success" icon="el-icon-check" circle @click="pushAllElements" />
          <el-button v-if="listSacs2.length == 0" type="warning" icon="el-icon-close" circle @click="pushAllElements" />
          <el-link v-if="listSacs1.length != 0 && listSacs2.length != 0" type="primary" class="wd-20 filter-item" @click="initDialogSacSearchList">Réinitialiser</el-link>
          <dnd-list :list1="listSacs1" :list2="listSacs2" list2-title="Les sacs du lot" list1-title="Sacs à ajouter au conteneur" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import DndList from '@/components/DndSacList';
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
const lotsResource = new Resource('lots');
const lotInfosResource = new Resource('lot-infos');
const conteneurResource = new Resource('conteneurs');
const sacsResource = new Resource('sacs');

export default {
  name: 'ProduitsList',
  // components: { Pagination },
  components: { DndList },
  directives: { waves, permission, role },
  data() {
    return {
      tableData: [],
      tableHeader: [],
      drawer: false,
      lot: {},
      list: [],
      total: 0,
      detail: false,
      detailData: [],
      detailM: false,
      detailDataM: null,
      loading: true,
      downloading: false,
      produitCreating: false,
      editing: false,
      activeList: 'disponibles',
      communeFilter: null,
      villageFilter: null,
      producteurFilter: null,
      currentProduit: {},
      activeName: '1',
      affectSacConteneurDialog: false,
      conteneurList: [],
      conteneur_id: null,
      conteneur_beneficiaire_id: null,
      sacsSelection: [],
      sac_search_keyword: '',
      board_sac_search_keyword: '',
      query: {
        page: 1,
        limit: this.$limit,
        keyword: '',
      },
      dialogCreateProduit: false,
      currentProduitId: 0,
      rules: {
        nom: [{ required: true, message: this.$t('produit.NomRequired'), trigger: 'blur' }],
      },
      fileList: [],
      listSacs1: [],
      listSacs2: [],
      select_list: [],
    };
  },
  computed: {

  },
  created() {
    // this.resetNewProduit();
    this.getLot();
    this.getLotInfos();
    this.getConteneursList();
  },
  /* mounted() {
    this.getLot();
    // this.getLotInfos();
  }, */
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array) {
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('produit.nom'), this.$t('general.description')];
        const filterVal = ['id', 'nom', 'description'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Liste des produits SCIAGRI_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    onChange(file, fileList) {
      var a = this.fileList;
      a.push({ name: file.name, url: file.url });
      // console.log('onChange begin');
      this.fileList = a;
      // console.log(this.fileList);
      // console.log('onChange end');
    },
    initDialogSacSearchList() {
      this.listSacs2 = this.list;
      this.listSacs1 = [];
    },
    initSearch() {
      this.handleFilterLots();
    },
    handleFilterLots() {
      this.query.page = 1;
      this.list = [];
      this.getLotInfos();
    },
    async getLot() {
      this.loading = true;
      const { data } = await lotsResource.get(this.$route.params.id);
      this.lot = data;
      this.loading = false;
    },
    async getLotInfos() {
      this.loading = true;
      const { data } = await lotInfosResource.get(this.$route.params.id);
      var sacsdata = [];
      var listSacs2Filtre = [];
      if (data.sacs.length > 0) {
        for (let index = 0; index < Object.values(data.sacs).length; index++) {
          var element = Object.values(data.sacs)[index];
          sacsdata.push(element);
          if (element.conteneur_id == null && element.decharger == null && element.rejeter == null) {
            listSacs2Filtre.push(element);
          }
        }
        this.list = sacsdata;
        this.listSacs2 = listSacs2Filtre;
      }
      this.loading = false;
    },
    getRecolteLink(id){
      console.log('recolteId : ', id);
      this.$router.push({ path: '/recoltes/' + id });
    },
    getProducteurLink(id){
      console.log('producteurId : ', id);
      this.$router.push({ path: '/producteurs/' + id });
    },
    getParcelleLink(id){
      console.log('parcelleId : ', id);
      this.$router.push({ path: '/parcelles/' + id });
    },
    async telechargerBonDeLivraison(){
      var { data } = await lotsResource.sublist('/bon-livraison' + '/' + this.$route.params.id, this.query);
      window.open(data, '_blank');
      console.log('PDF : ', data);
    },
    async telechargerFicheCollecte(){
      const sac_ids = [];
      this.list.forEach(sac => {
        sac_ids.push(sac.id);
      });
      const donnee = { 'sac_ids': sac_ids };
      var { data } = await lotsResource.extra('fiche-collecte' + '/' + this.$route.params.id, donnee);
      console.log('PDF : ', data);
      window.open(data, '_blank');
    },
    async lotLivre(){
      const todaydate = new Date();
      lotsResource.update(this.$route.params.id, { 'date_livr': todaydate })
        .then(async(response) => {
          this.$message({
            message: 'Succès! Le lot a été marqué comme livré',
            type: 'success',
            duration: 2000,
          });
          await new Promise((resolve) => setTimeout(resolve, 2050));
          window.location = window.location.href;
        }).catch(error => {
          this.$message({
            type: 'error',
            message: 'Erreur !',
          });
          console.log(error);
        });
    },
    cancelMessage(){
      this.$message({
        type: 'info',
        message: 'Annulé !',
      });
    },
    async getConteneursList() {
      this.loading = true;
      const { data } = await conteneurResource.list(this.query);
      this.conteneurList = data.reverse();
      this.loading = false;
    },
    findSac(name){
      const results = [];
      this.listSacs2 = this.list.filter(element => element.conteneur_id == null && element.decharger == null && element.rejeter == null);
      if (name !== ''){
        this.listSacs2.forEach((sac) => {
          if (sac.code.includes(name.toUpperCase())){
            results.push(sac);
          }
        });
        this.listSacs2 = results;
      }
    },
    board_find_sac(name){
      const results = [];
      if (name === ''){
        this.getLotInfos();
      } else {
        this.list.forEach((sac) => {
          if (sac.code.includes(name.toUpperCase()) || sac.producteur.nom.toUpperCase().includes(name.toUpperCase()) || sac.producteur.prenom.toUpperCase().includes(name.toUpperCase())){
            results.push(sac);
          }
        });
        this.list = results;
      }
    },
    envoyerSacConteneur(conteneur_id){
      if (conteneur_id == null) {
        this.$message({
          type: 'error',
          message: 'Veuillez selectionner le conteneur',
        });
        return;
      }
      if (this.listSacs1.length === 0) {
        this.$message({
          type: 'error',
          message: 'Veuillez ajouter des sacs',
        });
        return;
      }
      const sac_ids = [];
      this.listSacs1.forEach(sac => {
        sac_ids.push(sac.id);
      });
      const donnee = { 'sac_ids': sac_ids };
      console.log('le conteneur selectioné ', conteneur_id, donnee);
      const affectationSacConteneurResource = new Resource('conteneurs_enregistrer_sacs/' + conteneur_id);
      affectationSacConteneurResource.store(donnee).then(response => {
        this.$message({
          type: 'success',
          message: response.message,
        });
      })
        .catch(error => {
          console.log('error ', error);
        })
        .finally(() => {
          this.affectSacConteneurDialog = false;
          this.getConteneursList();
          this.listSacs1 = [];
        });
    },
    async rejeterSac(id){
      await sacsResource.extra('rejeter', { 'sac_ids': [id] }).then(response => {
        this.$message({
          type: 'success',
          message: response.message,
        });
        this.getLotInfos();
      }).catch(error => {
        this.$message({
          type: 'error',
          message: 'Echec',
        });
        console.log('error ', error);
      });
    },
    async dechargerSac(id){
      await sacsResource.extra('decharger', { 'sac_ids': [id] }).then(response => {
        this.$message({
          type: 'success',
          message: response.message,
        });
        this.getLotInfos();
      }).catch(error => {
        this.$message({
          type: 'error',
          message: 'Echec',
        });
        console.log('error ', error);
      });
    },
    pushAllElements(){
      if (this.listSacs1.length === 0) {
        this.listSacs1 = this.listSacs2;
        this.listSacs2 = [];
      } else {
        this.listSacs2 = this.listSacs1;
        this.listSacs1 = [];
      }
    },
    async getSubSacList(indication) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      if (indication === 'rejetés') {
        const { data } = await lotInfosResource.sublist('/' + this.$route.params.id, { 'rejeter': 'oui' });
        var sacsdata = [];
        if (data.sacs.length > 0) {
          for (let index = 0; index < Object.values(data.sacs).length; index++) {
            var element = Object.values(data.sacs)[index];
            sacsdata.push(element);
          }
        }
        this.list = sacsdata;
      }
      loading.close();
    },
  },
};
</script>

<style lang="scss" scoped>
  span.el-tag.el-tag--info.el-tag--medium.el-tag--light {
    text-align: center;
    width: 100%;
    display: block;
    height: auto;
  }
  span.el-tag.el-tag--dark.el-tag--medium.el-tag--light {
    text-align: center;
    width: 100%;
    display: block;
    height: auto;
  }
  .codeclass {
    float:left;
  }
  .text {
    font-size: 24px;
  }

  .item {
    margin-bottom: 28px;
  }
.edit-input {
  padding-right: 100px;
}

.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}

.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}

.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;

  .block {
    float: left;
    min-width: 250px;
  }

  .clear-left {
    clear: left;
  }
}

</style>
