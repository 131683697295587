<template>
  <div class="app-container">
    <div class="page-title">
      <h2>La Collecte</h2>
    </div>
    <el-card class="box-card filter-card just-for-admin">
      <div class="filter-container">
        <el-row :gutter="20">
          <el-col :span="3">
            <div>
              <el-tag v-if="!recolte.recolte_cloturee" type="danger" class="mx-1" effect="light" round>EN COURS</el-tag>
            </div>
          </el-col>
          <el-col :span="3">
            <div>
              <el-tag v-if="recolte.recolte_cloturee" type="success" class="mx-1" effect="light" round>CLÔTURÉE</el-tag>
            </div>
          </el-col>
          <!-- <el-col :span="4">
            <div>
              <el-tag type="info">
                Code: <span style="font-weight:bold">{{ recolte.code }}</span>
              </el-tag>
            </div>
          </el-col> -->
          <el-col :span="3">
            <div>
              <el-tag type="info">
                Date réc: <span style="font-weight:bold">{{ recolte.date_recolte_libelle }}</span></el-tag>
            </div>
          </el-col>
          <el-col :span="4">
            <div>
              <a @click="getProducteurLink(recolte.parcelle.parcelle_producteur.producteur_id)">
                <el-tag type="info">Producteur: <span style="font-weight:bold">{{ recolte.producteur }}</span></el-tag>
              </a>
            </div>
          </el-col>
          <el-col :span="3">
            <div>
              <el-tag type="info">Superficie: <span style="font-weight:bold">{{ recolte.parcelle.superficie }}</span></el-tag>
            </div>
          </el-col>
          <el-col :span="3">
            <div>
              <el-tag type="info">
                <span style="font-weight:bold"><a @click="getParcelleLink(recolte.parcelle.id)">Parcelle: {{ recolte.parcelle.code }}</a></span>
              </el-tag>
            </div>
          </el-col>
          <el-col :span="3">
            <div>
              <el-tag type="info">Poid total: <span style="font-weight:bold">{{ recolte.poids_total }}</span></el-tag>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <el-card v-loading="loading" class="box-card">
      <h3 style="color:#30B08F">Les sacs de la Collecte ({{ recolte.nbrs_sac }})</h3>
      <el-row :gutter="12">
        <el-col v-for="item in list" :key="item.code" :span="6">
          <el-card shadow="never">
            <el-col :span="8" align="center">
              <div class="text item">
                <el-avatar shape="square" :size="100" src="https://res.cloudinary.com/dwj6sdjxn/image/upload/v1669370400/fertilizer_2_amfrld.png" />
              </div>
            </el-col>
            <el-col :span="16">
              <div class="text item">
                <el-tag type="info"><span style="font-weight:bold; font-size:medium">{{ item.code }}</span></el-tag>&nbsp;
                <el-tag type="info"><span style="font-weight:bold; font-size:medium">Poids: {{ item.poids + ' Kg ' }}</span></el-tag>&nbsp;
              </div>
            </el-col>
          </el-card>
        </el-col>
      </el-row>

    </el-card>
  </div>
</template>

<script>
// import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
const recolteResource = new Resource('recoltes');
// const sacResource = new Resource('sacs/info');

export default {
  name: 'RecolteSacList',
  // components: { Pagination },
  components: {},
  directives: { waves, permission, role },
  data() {
    return {
      recolte: {},
      loading: true,
      list: [],
      query: {
        page: 1,
        limit: this.$limit,
        keyword: '',
      },
    };
  },
  computed: {

  },
  created() {
    this.getRecolte();
    this.loading = false;
  },
  mounted() {
    //
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array) {
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    async getRecolte() {
      console.log('abrrrrrrrrrrrrrrrr', this.$route.params.id);
      const { data } = await recolteResource.get(this.$route.params.id);
      this.recolte = data;
      console.log('===== EVRA', this.recolte);
      /* if (this.recolte.sacs.length > 0) {
        for (let index = 0; index < Object.values(data.sacs).length; index++) {
          var element = Object.values(data.sacs)[index];
          console.log('ssssssss', element.recolte.id);
          this.list.push(element);
        }
        console.log('LOTINFOS : ', this.list);
      } */
      this.list = data.sacs;
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    getRecolteLink(id){
      console.log('recolteId : ', id);
      this.$router.push({ path: '/recoltes/' + id });
    },
    getProducteurLink(id){
      console.log('producteurId : ', id);
      this.$router.push({ path: '/producteurs/' + id });
    },
    getParcelleLink(id){
      console.log('parcelleId : ', id);
      this.$router.push({ path: '/parcelles/' + id });
    },

  },
};
</script>

<style lang="scss" scoped>
  .text {
    font-size: 24px;
  }

  .item {
    margin-bottom: 28px;
  }
.edit-input {
  padding-right: 100px;
}

.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}

.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}

.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;

  .block {
    float: left;
    min-width: 250px;
  }

  .clear-left {
    clear: left;
  }
}
</style>
